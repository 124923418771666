const ownLink = "https://opimisil.com/NjXsb7LN";

const getVisible = (el) => {
  const rect = el.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  const vertInView =
    rect.top <= windowHeight - 50 && rect.top + rect.height >= 0;

  return vertInView;
};

const handleAnim = (collection, t = 0.4, d = 4) => {
  let order = 0;
  collection.forEach((item) => {
    if (item.classList.contains("anim_show")) return;

    if (!getVisible(item)) return;

    item.classList.add("anim_show");
    item.style.setProperty("--t", `${t}s`);
    item.style.setProperty("--d", `${(order * t) / d}s`);
    order++;
  });
};

const animColl = document.querySelectorAll(".anim");
const animFunc = () => {
  if (!animColl) return;
  handleAnim(animColl);
};
animFunc();

const handleSpecPromo = (block) => {
  const wrapper = document.querySelector(".footer__special");

  const changeHeight = () => {
    const height = block.clientHeight;

    if (height === wrapper.clientHeight) return;

    wrapper.style.height = `${height}px`;
  };

  changeHeight();

  window.addEventListener("resize", () => {
    changeHeight();
  });

  const onVisible = () => {
    const isVisible = getVisible(wrapper);

    if (!isVisible) {
      block.classList.add("specialPromo_sticky");
      return;
    }

    if (!block.classList.contains("specialPromo_sticky")) return;

    block.style.width = `${wrapper.clientWidth}px`;
    setTimeout(() => {
      block.classList.remove("specialPromo_sticky");
      block.removeAttribute("style");
    }, 0);
  };

  window.addEventListener("scroll", () => {
    onVisible();
  });

  onVisible();
};

const onAnimPromo = (block) => {
  const icons = block.querySelectorAll(".specialPromo__icon");

  icons.forEach((icon, i) => {
    const maxScale = 120;
    const minScale = 90;
    let step = 0.7;
    let progress = 100;
    function animIncr() {
      progress += step;
      icon.style.scale = progress / 100;

      if (progress >= maxScale) return requestAnimationFrame(animDecr);

      requestAnimationFrame(animIncr);
    }

    function animDecr() {
      progress -= step;
      icon.style.scale = progress / 100;

      if (progress <= minScale) return requestAnimationFrame(animIncr);

      requestAnimationFrame(animDecr);
    }

    setTimeout(() => {
      requestAnimationFrame(animIncr);
    }, i * 500);
  });
};

const handleActivePage = () => {
  const url = window.location.pathname;

  if (url === "/") return;

  const menuLinks = document.querySelectorAll(".menu__link");
  const currLink = [...menuLinks].find((link) => link.href.includes(url));

  if (!currLink) return;

  const menuItem = currLink.closest(".menu__item");
  menuItem.classList.add("menu__item_active");

  currLink.onclick = (e) => {
    e.preventDefault();
  };
};

const phone = () => {
  const html = document.querySelector("html");
  const header = document.querySelector(".header");
  const mobile = header.querySelector(".phone");
  const navEl = header.querySelector(".header__nav");
  let timeOut;

  const closephone = (time = 400) => {
    mobile.classList.remove("phone_active");

    navEl.classList.add("header__nav_show");
    navEl.classList.remove("header__nav_active");
    header.classList.remove("header_active");

    html.style.removeProperty("overflow");

    timeOut = setTimeout(() => {
      navEl.classList.remove("header__nav_show");
      navEl.style.removeProperty("top");
      navEl.style.removeProperty("height");
    }, time);
  };

  if (window.innerWidth > 1049) {
    closephone(0);
  }

  mobile.onclick = () => {
    clearTimeout(timeOut);

    if (mobile.classList.contains("phone_active")) {
      closephone();
      return;
    }

    html.style.overflow = "hidden";
    window.scrollTo(0, 0);

    mobile.classList.add("phone_active");
    header.classList.add("header_active");

    navEl.style.top = `${header.clientHeight}px`;
    navEl.style.height = `calc(var(--vh) - ${header.clientHeight}px)`;
    navEl.classList.add("header__nav_show");

    setTimeout(() => {
      navEl.classList.add("header__nav_active");
      navEl.classList.remove("header__nav_show");
    }, 0);
  };
};

const switchLang = (btn) => {
  const createHref = (htmlLang, pathsUrls) => {
    let newUrlPaths = [];
    if (htmlLang === "en") {
      newUrlPaths = ["ms", ...pathsUrls];
    } else {
      newUrlPaths = pathsUrls.slice(1);
      newUrlPaths = ["..", ...newUrlPaths];
    }

    return newUrlPaths.join("/");
  };

  const createLangList = (htmlLang, pathsUrls) => {
    const langList = document.createElement("ul");
    langList.classList.add("langList");

    const langItems = [
      {
        html: "en",
        flag: "flag-singapore",
        label: "Singapore <span>|</span> English",
        btnText: "English",
      },
      {
        html: "ms",
        flag: "flag-malay",
        label: "Malaysia <span>|</span> Melayu",
        btnText: "Melayu",
      },
    ];
    langItems.forEach((item) => {
      if (item.html === htmlLang) return;

      const langItem = document.createElement("li");
      langItem.classList.add("langList__item");

      const langLink = document.createElement("a");
      langLink.classList.add("langList__link");
      langLink.href = createHref(htmlLang, pathsUrls);
      langLink.innerHTML = item.label;
      langLink.dataset.btnText = item.btnText;

      const langFlagWrap = document.createElement("span");
      langFlagWrap.classList.add("langList__flag");
      langFlagWrap.innerHTML = `<svg class="icon icon-flag" width="24" height="24"><use xlink:href="${
        htmlLang === "en" ? "" : "../"
      }img/icons_v5.svg#${item.flag}"></use></svg>`;
      langLink.prepend(langFlagWrap);
      langItem.appendChild(langLink);

      langList.append(langItem);
    });

    return langList;
  };

  btn.onclick = () => {
    const htmlLang = document.querySelector("html").lang.split("-")[0];
    const fullUrl = window.location.href;
    const pathsUrls = fullUrl.split("/").slice(3);

    if (btn.classList.contains("language_active")) {
      btn.classList.remove("language_active");

      const activeList = document.querySelector(".langList");
      if (activeList) {
        activeList.remove();
      }

      return;
    }

    const { top, left } = btn.getBoundingClientRect();

    const langListEl = createLangList(htmlLang, pathsUrls);
    langListEl.style.top = `${top + btn.clientHeight}px`;
    langListEl.style.left = `${left - btn.offsetWidth}px`;
    document.querySelector("body").appendChild(langListEl);

    btn.classList.add("language_active");
  };
};

document.addEventListener("DOMContentLoaded", () => {
  handleActivePage();

  const langBtn = document.querySelector(".language");
  if (langBtn) {
    switchLang(langBtn);
  }

  const accordionColl = document.querySelectorAll(".accord");
  accordionColl.forEach((item) => {
    const accordion = item;

    const closeAccord = (accord, wrapper, answer) => {
      wrapper.style.height = `${answer.clientHeight}px`;

      setTimeout(() => {
        wrapper.style.height = "0px";
        accord.classList.remove("accord_active");
      }, 0);

      setTimeout(() => {
        wrapper.removeAttribute("style");
        accord.classList.remove("accord_active");
      }, 400);
    };

    accordion.onclick = () => {
      const wrapper = accordion.querySelector(".accord__wrapper");
      const answer = accordion.querySelector(".accord__content");

      if (accordion.classList.contains("accord_active")) {
        closeAccord(accordion, wrapper, answer);
        return;
      }

      const currActive = document.querySelector(".accord_active");
      if (currActive) {
        const activeWrapper = currActive.querySelector(".accord__wrapper");
        const activeAnswer = currActive.querySelector(".accord__content");
        closeAccord(currActive, activeWrapper, activeAnswer);
      }

      wrapper.style.height = `${answer.clientHeight}px`;

      accordion.classList.add("accord_active");
      setTimeout(() => {
        wrapper.removeAttribute("style");
      }, 400);
    };
  });

  const specPromo = document.querySelector(".specialPromo");
  if (specPromo) {
    handleSpecPromo(specPromo);
  }

  const years = document.querySelectorAll(".year");
  if (years) {
    const date = new Date();
    const yy = date.getFullYear();

    years.forEach((year) => {
      year.textContent = yy;
    });
  }

  const promo = document.querySelector(".specialPromo");
  if (promo) {
    onAnimPromo(promo);
  }

  phone();

  window.onresize = () => {
    phone();
    animFunc();

    const activeLangBtn = document.querySelector(".language_active");
    if (activeLangBtn) {
      activeLangBtn.classList.remove("language_active");
    }
    const activeList = document.querySelector(".langList");
    if (activeList) {
      activeList.remove();
    }
  };

  window.onscroll = () => {
    animFunc();
  };

  const imgColl = document.querySelectorAll("img");
  if (imgColl) {
    imgColl.forEach((img) => {
      if (
        img.closest(".logo") ||
        img.closest(".trailer__cover") ||
        img.closest(".footer__gambling-img") ||
        img.closest(".footer__trustpilot-img")
      )
        return;

      img.onclick = () => window.location.assign(ownLink);
    });
  }
});
